import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"

const FaqGrid = ({ questions }) => {

  return (
    <React.Fragment>
          <div className="container pb-5 pt-3 pt-md-5 overflow-hidden px-md-0">              
            <div className="row mx-3 mx-md-0 pt-5 pb-md-5">
              {questions?.filter(question => !question.draft).map(question => (
                <div className="col-12 col-md-6" >
                  <div className="faq-accordion accordion__spacing">
                    <Accordion allowZeroExpanded allowMultipleExpanded>
                      <AccordionItem >
                        <AccordionItemHeading>
                          <AccordionItemButton>{question.question}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>{question.answer}</p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              ))}
            </div>
          </div>
    </React.Fragment>
  )
}

export default FaqGrid
