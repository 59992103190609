import React from "react"
import FadeLeftAnimation from "../../common/FadeLeftAnimation";

const FaqBanner = props => {
  const { subheader, title } = props
  return (
    <div className="contact-banner">
      <div className="container">
        <div className="row align-items-center main-banner-content ">
          <div className="col-12 col-lg-10">
            <div className="text-white innerpage-title">
              <FadeLeftAnimation>
                <h4 className="">{subheader}</h4>
              </FadeLeftAnimation>
              <FadeLeftAnimation>
                <h1 className="text-primary">{title}</h1>
              </FadeLeftAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqBanner
