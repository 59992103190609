import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/global/Layout";
import FaqBanner from "../components/global/faq/FaqBanner";
import FaqGrid from "../components/global/faq/FaqGrid";
import { parseSeoFromQuery } from "../components/global/SEO";

export default function Faq(props) {
  // Not ordered as in CMS! =/
  const data = props?.data?.markdownRemark?.frontmatter;
  const questions = props?.data?.allMarkdownRemark?.edges.map(edge => ({
    ...edge?.node?.frontmatter,
  }));

  return (
    <Layout {...parseSeoFromQuery(props.data)}>
      <FaqBanner {...data?.faqBanner} />
      <FaqGrid questions={questions} {...data?.faqBanner} />
    </Layout>
  );
}

export const query = graphql`
  query ($page: String, $locale: String) {
    markdownRemark(
      frontmatter: { page: { eq: $page }, locale: { eq: $locale } }
    ) {
      frontmatter {
        ...seo
        faqBanner {
          subheader
          title
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { page: { eq: "question" }, locale: { eq: $locale } }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            draft
            order
            question
            answer
          }
        }
      }
    }
  }
`;
